import React from "react";

import { app } from "js/namespaces";
import { ds } from "js/core/models/dataService";
import getLogger, { LogGroup } from "js/core/logger";
import { Presentation } from "js/core/models/presentation";
import { Slide } from "js/core/models/slide";
import { prepareToUnlockAudio } from "js/core/utilities/audioUtilities";

import { LoadingPage } from "js/react/components/LoadingPage";

import PlayerContext from "./PlayerContext";
import { getAnalytics, AnalyticsContextType } from "../helpers/analytics";

const logger = getLogger(LogGroup.PLAYER);

export default class PlayerOfflineContext extends PlayerContext {
    constructor() {
        super();

        this.state = {
            fetching: true
        };
    }

    async componentDidMount() {
        try {
            // Loading presentation and slides and saving them as context
            const res = await fetch("/userData/data.json");
            const data = await res.json();

            // Fake user
            app.user = {
                id: data.presentation.userId,
                get: () => { },
                getEmail: () => { },
                isOldBasicUser: () => false,
                features: {
                    isFeatureEnabled: () => true
                }
            };

            // Main context data initialization
            const presentation = new Presentation(data.presentation, { disconnected: true });

            const slides = Object.entries(data.slides).reduce((slides, [id, slide]) => {
                slides[id] = new Slide({ ...slide, id }, { disconnected: true, presentation });
                return slides;
            }, {});

            this.contextData = {
                ...this.contextData,
                isLoggedIn: true,
                presentation,
                slides,
                showBranding: false,
                isMobileOrTablet: false,
                requireEmail: false,
                username: app.user?.get("email"),
                onCopyDeck: false,
                allowCopyLink: false,
                allowSocialSharing: false,
                onSwitchPresentation: false,
                onEditPresentation: false,
                onDownloadPdf: false,
                creator: data.creator,
                isPresenterAvailable: window.electronDisplaysCount > 1,
                presenterUrl: "http://localhost/index.html?presenter",
                presenterWindowTarget: "_presenter",
                analytics: getAnalytics(AnalyticsContextType.OFFLINE, false, null, false, presentation)
            };

            // Initialize ds
            ds.dummySetup();

            // Add assets so they're available for the elements
            Object.entries(data.assets).forEach(([id, asset]) => {
                ds.assets.add({ ...asset.model, id }, { loadModels: false });
            });

            // Load theme
            await app.themeManager.loadTheme(presentation);

            // Preload data necessary for synchronous audio playback unlocking
            await prepareToUnlockAudio();

            this.setState({ fetching: false });
        } catch (err) {
            logger.error(err, "PlayerOfflineContext load failed");
        }
    }

    render() {
        const { fetching } = this.state;

        if (fetching) {
            return <LoadingPage />;
        }

        return this.renderChildrenWithContext();
    }
}
