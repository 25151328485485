import React, { Component } from "react";
import styled from "styled-components";
import { Button, TextField, Link } from "@material-ui/core";

import { _ } from "js/vendor";
import { mountAuth } from "js/react/components/Dialogs/mountAuth";
import { emailRegex } from "js/core/utilities/regex";

import BeautifulLogo from "js/react/components/BeautifulLogo";
import { BeautifulDialog, DialogContent } from "js/react/components/Dialogs/BaseDialog";
import { Gap20, Gap30 } from "js/react/components/Gap";

const FormRow = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 16px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    &.muted {
        color: #999;
    }
    &.primary {
        font-size: 16px;
    }
    &.sub-1 {
        font-size: 12px;
    }
    &.sub-2 {
        font-size: 10px;
    }
`;

interface Props {
    onSuccess?: (email: string) => void;
    closeDialog: () => void;
}

interface State {
    email: string;
    emailError: string;
}

export default class EmailSubmitDialog extends Component<Props, State> {
    state: State = {
        email: "",
        emailError: ""
    };

    validateEmail = (email: string) => {
        if (!email) {
            return "Required";
        }

        if (!emailRegex.test(email)) {
            return "Invalid email";
        }

        return "";
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const email = event.target.value.trim();

        // If the email was invalid, revalidate the new value
        if (this.state.emailError) {
            const emailError = this.validateEmail(email);
            this.setState({
                email,
                emailError
            });
        } else {
            this.setState({
                email
            });
        }
    }

    handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        // Prevent window.keydown events from firing.
        event.stopPropagation();
    }

    handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            this.onSubmit();
        }
    }

    handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.select();
    }

    handleBlur = () => {
        const { email } = this.state;

        const emailError = this.validateEmail(email);
        this.setState({ emailError });
    }

    onSubmit = () => {
        const { email } = this.state;

        const emailError = this.validateEmail(email);
        if (emailError) {
            this.setState({ emailError });
            return;
        }

        const { onSuccess, closeDialog } = this.props;
        onSuccess && onSuccess(email);
        closeDialog();
    }

    onSignIn = () => {
        const { onSuccess, closeDialog } = this.props;

        mountAuth({
            onSuccess: user => onSuccess && onSuccess(user.email),
            allowClose: false
        });

        closeDialog();
    }

    render() {
        const { closeDialog } = this.props;
        const { emailError, email } = this.state;

        return (
            <BeautifulDialog
                preventClose
                closeDialog={closeDialog}
                closeButton={false}
                style={{ zIndex: 10000 }}
            >
                <DialogContent>
                    <Gap30 />
                    <FormRow> {/* @ts-ignore */}
                        <BeautifulLogo noLink darkOnLight width={120} />
                    </FormRow>
                    <FormRow className="primary">
                        This presentation requires you to enter your email before viewing.
                    </FormRow>
                    <FormRow>
                        <TextField
                            onKeyPress={this.handleKeyPress}
                            onKeyDown={this.handleKeyDown}
                            onFocus={this.handleFocus}
                            onBlur={this.handleBlur}
                            autoFocus
                            placeholder="Email"
                            value={email}
                            fullWidth={true}
                            type="email"
                            variant="outlined"
                            helperText={emailError}
                            error={!!emailError}
                            onChange={this.handleChange}
                        />
                    </FormRow>
                    {!emailError && <Gap20 />}
                    <FormRow>
                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            onClick={this.onSubmit}
                            disabled={!!emailError || !email}
                            fullWidth
                        >
                            Start Presentation
                        </Button>
                    </FormRow>
                    <FormRow className="muted sub-1">
                        <div>
                            <span>OR, </span>
                            <Link
                                href="#"
                                onClick={this.onSignIn}
                            >
                                SIGN IN
                            </Link>
                        </div>
                    </FormRow>
                </DialogContent>
            </BeautifulDialog>
        );
    }
}
